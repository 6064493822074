import { __bucket } from "~/stores/bucket.store";
import { __identifier } from "~/stores/identifier.store";

export default defineNuxtRouteMiddleware(async to => {
	const bucketId = to.params.id;
	if (!bucketId) {
		log("❌ No bucket id found");
		return navigateTo("/");
	}

	log("✅ Has bucket id: " + bucketId);
	const bucket = __bucket().getLocalBucket(bucketId.toString());

	if (!bucket) {
		log("⌛ No bucket found, fetching");
		const newBucket = await __bucket().fetchBucket(bucketId.toString());
		const identifier = __identifier().getIdentifierOfBucket(bucketId.toString());
		if (!newBucket || !identifier || !identifier.active) {
			log("❌ No bucket found");
			return navigateTo("/");
		}
	}

	log("✅ Bucket found");
});
